<template>
  <hb-image
    :image="image"
    :loading-type="loadingType"
    :show-largest="showLargest"
    :allow-image-preload="allowImagePreload"
  />
</template>

<script lang="ts">
import type { SrcSet } from '@homebourse/api-client'
import HbImage from '~/components/base/HbImage.vue'

export default {
  name: 'HomeImage',
  components: { HbImage },
  props: {
    image: { type: Object as () => SrcSet, required: true },
    loadingType: {
      type: String as () => 'lazy' | 'eager',
      default: () => 'lazy',
    },
    showLargest: { type: Boolean, default: () => false },
    allowImagePreload: { type: Boolean, default: () => false },
  },
}
</script>

<style scoped lang="scss"></style>
